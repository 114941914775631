<template>
  <div :class="classBlock">
    <Menu :class="classBlock+'-menu ' + getLogoColor"/>
    <router-link :to="'/'">
      <div :class="classBlock+'-logo ' + getLogoColor"></div>
    </router-link>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import Menu from './Menu.vue'
  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'HeaderCc',
    // components imported
    components: {Menu},
    // component properties
    props: {
      classNames: [], // string[],
      logoColor: String
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'HeaderCc'
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      },

      getLogoColor: function () {
        return this.logoColor
      }
    },

    // -------------------------------------------- methods
    methods: {},

    // -------------------------------------------- mounted
    mounted: function () {
      // if (!this.logoColor) this.logoColor = 'black'
    },

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'HeaderCc';
    .HeaderCc {
        // --------------------------------------------------------------------- CONFIG
        margin: 30px 0;
        position: absolute;
        width: 100vw;
        top: 0;
        padding: 0 40px;
        @include mobile {
          position: absolute;
          width: 100%;
        }
        // --------------------------------------------------------------------- BLOCK

        // --------------------------------------------------------------------- ELEMENTS
        &-logo{
          width: 10vw;
          height: auto;
          min-height: 315px;
          background-size: contain;
          display: inline-block;
          float: right;
          z-index: 3;
          position: relative;
          &.white{
            background-image: url("../assets/svg/LOGO-CC-white.svg");
          }
          
          &.black{
            background-image: url("../assets/svg/LOGO-CC-black.svg");
          }

          @include mobile {
            width: 69px;
            height: 145px;
          }
        }
        &-menu{
          display: inline-block;
        }
        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

