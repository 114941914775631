<template>
  <div :class="classBlock">
    <div id="nav-icon" :class="getOpenStateClass"><span></span><span></span><span></span><span></span></div>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'BurgerButton',
    // components imported
    components: {},
    // component properties
    props: {
      classNames: [], // string[],
      isOpen: Boolean
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'BurgerButton'
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      },
      getOpenStateClass: function () {
        console.log('getOpenStateClass')
        if (this.isOpen) return 'open'
        else return ''
      }
    },

    // -------------------------------------------- methods
    methods: {},

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';
    $burger_width         : 29px;
    $burger_width_2       : 21px;
    $burger_line_height   : 3px;

    $burger_line_height_2 : 3px;
    $burger_color         : $black;
    $burger_color_white   : $black;
    $burger_height        : $burger_line_height*6;
    $burger_height_2        : $burger_line_height_2*6;
    // Component name
    $COMPONENT: 'BurgerButton';
    .BurgerButton {
        // --------------------------------------------------------------------- CONFIG
        min-height: $burger_line_height*16;
        min-width: $burger_width;
        // --------------------------------------------------------------------- BLOCK
        #nav-icon {
          width: $burger_width;
          height: $burger_height;
          position: absolute;
          right:$burger_height/2;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .5s ease-in-out;
          -moz-transition: .5s ease-in-out;
          -o-transition: .5s ease-in-out;
          transition: .5s ease-in-out;
          cursor: pointer;

          span {
            display: block;
            position: absolute;
            height: $burger_line_height - 1px;
            width: 100%;
            background: $black;
            border-radius: $burger_line_height / 2;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
            &:nth-child(1) {
              top: 0px;
            }
            &:nth-child(2), &:nth-child(3) {
              top: $burger_line_height*4;
            }
            &:nth-child(4) {
              top: $burger_line_height*8;
            }
            &:nth-child(1) {
              -webkit-transform: rotate(-5deg);
              -moz-transform: rotate(-5deg);
              -o-transform: rotate(-5deg);
              transform: rotate(-5deg);
            }
            &:nth-child(2) {
              -webkit-transform: rotate(7deg);
              -moz-transform: rotate(7deg);
              -o-transform: rotate(7deg);
              transform: rotate(7deg);
            }
            &:nth-child(3) {
              -webkit-transform: rotate(7deg);
              -moz-transform: rotate(7deg);
              -o-transform: rotate(7deg);
              transform: rotate(7deg);
            }
            &:nth-child(4) {
              -webkit-transform: rotate(-7deg);
              -moz-transform: rotate(-7deg);
              -o-transform: rotate(-7deg);
              transform: rotate(-7deg);
            }
          }

          &.open {
            span {
              &:nth-child(1) {
                top: $burger_line_height*2;
                width: 0%;
                left: 50%;
              }
              &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
              }
              &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
              &:nth-child(4) {
                top: $burger_line_height*2;
                width: 0%;
                left: 50%;
              }
            }
          }
        }
        &:active, &:focus, &:hover {
          outline: 0;
        }
        &-white{
          #nav-icon {
            span {
              background: $white;
            }
          }
        }

        // --------------------------------------------------------------------- ELEMENTS

        // --------------------------------------------------------------------- MODIFIERS

        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

