<template>
  <div :class="classBlock">
    <transition 
        name="fade"
        mode="out-in"
      >
      <div v-if="menuIsOpen" :class="classBlock + '-background background ' + getMenuState">
        <div :class="classBlock + '-content'">
          <router-link :to="'/'" @click.native="openMenu()">
            <div :class="[classBlock + '-item', classBlock + '-link']">
              Accueil
              <span :class="classBlock + '-item-number'">01</span>
            </div>
          </router-link>
          <!-- <router-link :to="'/'">
            <div :class="[classBlock + '-item', classBlock + '-link']">
              Gallerie
              <span :class="classBlock + '-item-number'">02</span>
            </div>
          </router-link> -->
          <router-link :to="'/contact'" @click.native="openMenu()">
            <div :class="[classBlock + '-item', classBlock + '-link']">
              Contact
              <span :class="classBlock + '-item-number'">03</span>
            </div>
          </router-link>
        </div>
      </div>
    </transition>
    <BurgerButton :isOpen="menuIsOpen" @click.native="openMenu()"/>
  </div>
</template>

<script>
  // -------------------------------------------- imports dependencies
  import BurgerButton from '../molecules/BurgerButton.vue'

  // -------------------------------------------- imports components

  export default {
    // -------------------------------------------- init
    name: 'Menu',
    // components imported
    components: {BurgerButton},
    // component properties
    props: {
      classNames: [] // string[]
    },

    // -------------------------------------------- components datas
    data: () => ({
      component: 'Menu',
      menuIsOpen: false
    }),

    // -------------------------------------------- computed properties
    computed: {
      /**
      * class block formater
      */
      classBlock: function () {
        return [
          this.component,
          ...[this.classNames],
        ].filter(v => v).join(' ');
      },
      getMenuState: function () {
        if (this.menuIsOpen) return 'open'
        return 'close'
      }
    },

    // -------------------------------------------- methods
    methods: {
      openMenu: function () {
        console.log('openMenu')
        this.menuIsOpen = !this.menuIsOpen
      }
    },

    // -------------------------------------------- mounted
    mounted: function () {},

    // -------------------------------------------- destroy
    destroy: function () {}
  } 
</script>

<style lang="scss">
    @import '../styles/reference';

    // Component name
    $COMPONENT: 'Menu';
    .Menu {
        // --------------------------------------------------------------------- CONFIG
        z-index: 2;
        position: absolute;
        
        // --------------------------------------------------------------------- BLOCK
        &-item{
          display:block;
          font-family: 'MonumentExtended', Arial, Helvetica, sans-serif;
          font-size: 50px;
          text-align: center;
          &-number{
            font-family: 'GTAmerica', Arial, Helvetica, sans-serif;
            font-size: 15px;
            vertical-align: middle;
            position: absolute;
            line-height: 50px;
            margin-left: 10px;
          }
        }
        &-content{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &-link{
          padding: 25px 50px;
          font-weight: bold;
        }
        &-contact{
          color: $white;
          border-color: $white
        }

        &-background{
          background-color: $home;
          width: 100vw;
          min-height: 100vh;
          height: 100%;
          position: fixed;
          z-index: 0;
          top: 0;
          left: 0;
        }

        .fade-enter-active,
        .fade-leave-active {
          transition: opacity 0.5s ease;
        }

        .fade-enter-from,
        .fade-leave-to {
          opacity: 0;
        }
        // --------------------------------------------------------------------- ELEMENTS

        // --------------------------------------------------------------------- MODIFIERS
        /* .open{
          opacity: 1;
        }
        .close{
          opacity: 0;
        } */
        // --------------------------------------------------------------------- CHILDREN

        // --------------------------------------------------------------------- PSEUDOS

        // --------------------------------------------------------------------- PATCHES

    }

</style>

